<template>
  <div class="top-banner" @click="redirectToV3">
    Beta Release of Next-Gen Connectors is here. Get it now!
  </div>
</template>

<script>
export default {
  name: 'TopBanner',
  methods: {
    redirectToV3() {
      window.location.href = '/v3'
    }
  }
}
</script>

<style scoped>
.top-banner {
  background-color: #0057e5;
  color: white;
  text-align: center;
  padding: 50px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease, font-size 0.3s ease;
}

.top-banner:hover {
  background-color: #0046b8;
  font-size: 1.05em; /* Increase font size by 5% on hover */
}
</style>
